

  .positive {
    background: rgb(25, 135, 84);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -2px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .negative {
    background: rgb(220, 53, 69);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -2px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .neutral {
    background: rgb(13, 110, 253);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -1px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .todo {
    background: #444;
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -1px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .empty {
    color: transparent;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;  
  }
  
  .highlighted-words {
    position: absolute;
    top: 0;
    padding-top: 10px;
    padding-left: 10px;
    left: 0;
    color: transparent;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  .text-field {
    color: white;
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    z-index: 10;
  }
  
  